import 'bootstrap-sass';
import 'datatables.net-bs';
import 'datatables.net-select-bs';

import './styles/default.scss'; 

import dt from 'datatables.net';
import dtrs from 'datatables.net-responsive';
import dtsl from 'datatables.net-select';

import jQuery from 'jquery';
import authenticateUser from './authenticate';
import setupTranslations from './i18n-bootstrapper';
import createElmSpaApp from './elm-bootstrapper';

dt(window, jQuery);
dtrs(window, jQuery);
dtsl(window, jQuery);

jQuery.getJSON("/config.json")
    .done((config) =>
            authenticateUser(config)
                .then(setupTranslations)
                .then(createElmSpaApp)
                .catch((error) => {if(error){console.error(error);}})
    );

