import i18next from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18nextXHRBackend from 'i18next-xhr-backend';

export default function (context) {

    return new Promise((resolve, _) => {
        i18next.use(i18nextBrowserLanguageDetector).use(i18nextXHRBackend)
        .init({
            fallbackLng: 'en',
            load: 'languageOnly',
            whitelist: ['en'],
        }, () => {
            if (i18next.hasResourceBundle(i18next.language)) {
                context.i18n = i18next.getResourceBundle(i18next.language);
            } else {
                context.i18n = i18next.getResourceBundle(i18next.options.fallbackLng[0]);
            }

            resolve(context);
        });
    });
    
}