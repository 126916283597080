import jQuery from "jquery";

import Elm from "./Main";

export default function bootstrapElm(context) {
	var clients = context.principal.identity.profile["client-id"];
	if (Array.isArray(clients) == false) {
		clients = [clients];
	}

	var storedClient =
		clients.includes(window.localStorage.getItem("active-client")) &&
		window.localStorage.getItem("active-client");

	var flags = Object.assign(
		{
			activeClient: storedClient || clients[0],
			clients: clients,
			accessToken: context.principal.identity.access_token,
			reportFileService: context.config.HALOLITECLIENT_REPORTFILESERVICE,
			readOnly:
				context.principal.identity.profile["type"].indexOf(
					"View Only"
				) !== -1,
			userEmail: context.principal.identity.profile.email,
			haloLiteApi: context.config.HALOLITECLIENT_HALOLITEAPI,
			termsAndConditions: context.config.HALOLITECLIENT_TERMSANDCONDITIONS
		},
		context.i18n
	);

	var program = Elm.Main.embed(document.getElementById("elm-handle"), flags);

	var requestAnimationFrame =
		window.requestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.msRequestAnimationFrame;

	jQuery(document).click(function(event) {
		if (!jQuery(event.target).closest("#MenuDropdown").length) {
			program.ports.userClickedAwayFromUserAccountDropdown.send(null);
			program.ports.userClickedAwayFromHelpDropdown.send(null);
		}
	});

	program.ports.notifyOfAction.subscribe(() => {
		var inactivityLogout = setTimeout(
			() => context.principal.logout(),
			1000 * 60 * 20
		);

		return () => {
			clearTimeout(inactivityLogout);
			inactivityLogout = setTimeout(
				() => context.principal.logout(),
				1000 * 60 * 20
			);
		};
	});

	program.ports.updateActiveClient.subscribe(clientId => {
		window.localStorage.setItem("active-client", clientId);
		window.location.href = "/";
	});

	program.ports.notifyUnauthorized.subscribe(() => context.principal.login());

	program.ports.logout.subscribe(() => context.principal.logout());

	program.ports.initDataTable.subscribe(args => {
		requestAnimationFrame(() => {
			var participants = args[0];
			var participantId = args[1];

			var table;
			if (jQuery.fn.dataTable.isDataTable("#js-participants__table")) {
				table = jQuery("#js-participants__table").DataTable();
			} else {
				table = jQuery("#js-participants__table").DataTable({
					language: {
						emptyTable: flags.i18nNoParticipants,
						zeroRecords: flags.i18nNoParticipants
					},
					select: {
						style: "single",
						info: false
					},
					info: false,
					autoWidth: false,
					paging: false,
					searching: false,
					ordering: true,
					responsive: {
						details: {
							type: false
						}
					},
					order: [],
					columns: [
						{ data: "firstName", responsivePriority: 2 },
						{ data: "lastName", responsivePriority: 2 },
						{ data: "email", responsivePriority: 1 },
						{ data: "progress", responsivePriority: 3 }
					],
					rowId: "id",
					createdRow: (row, data, _) => {
						var $row = jQuery(row);
						$row.addClass("clickable-row");
						$row.attr("data-participant-id", data.id);
					}
				});

				table.on("user-select", (_, dt, __, cell, ___) => {
					var row = dt.row(cell.index().row);
					program.ports.participantClicked.send(row.id());
				});

				table.on("select", () =>
					jQuery("#SidePanel").addClass("active")
				);

				table.on("deselect", () =>
					jQuery("#SidePanel").removeClass("active")
				);

				program.ports.updateParticipantInList.subscribe(participant =>
					table
						.row({ selected: true })
						.data(participant)
						.draw()
				);

				program.ports.deselectParticipant.subscribe(() =>
					table.rows().deselect()
				);
			}

			table.clear();
			table.rows.add(participants);
			table.draw();
			table.columns.adjust().responsive.recalc();

			if (participantId) {
				var row = table.row("#" + participantId);
				row.select();
			}
		});
	});
}
