import Oidc from 'oidc-client';

export default function authenticate(config) {
    var mgr = new Oidc.UserManager({
        authority: config.HALOLITECLIENT_IDENTITYSERVER,
        client_id: "halo_lite",
        redirect_uri: config.HALOLITECLIENT_SIGNINCALLBACK,
        response_type: "id_token token",
        scope: "openid profile halo_lite",
        silent_redirect_uri: config.HALOLITECLIENT_SILENTRENEW,
        automaticSilentRenew: true
    });

    mgr.events.addAccessTokenExpired(mgr.signoutRedirect);
    mgr.events.addUserSignedOut(mgr.signoutRedirect);

    return mgr.getUser().then((user) => {
        return new Promise((resolve, reject) => {
            if (!user) {
                reject();
                return;
            }
            window.goog('set', { 'user_id': user.profile.sub }); // Set the user ID using signed-in user_id.
            resolve({ principal: { identity: user, login: () => mgr.signinRedirect(), logout: () => mgr.signoutRedirect() }, config: config });
        });
    }
    ).catch(() =>
        new Promise((_, reject) => {
            mgr.signinRedirect();
            reject();
        })
    ); 
}